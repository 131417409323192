/*
 |-----------------------------------------------------------------------------
 | components/molecules/ContactPoint/ContactPoint.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.base {
	a {
		@include hyperlink($brand-4);
	}
}

.body {
	composes: base;
	font-size: type(20px, $font-size-body);
	line-height: 1.4;
}

.footer {
	@include font($size: rems(15px), $weight: 'medium');
	composes: base;

	li {
		&:not(:last-child) {
			margin-bottom: div($grid-gap, 2);
		}
	}
}
